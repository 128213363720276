/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { convertDateToDateForComponent, convertDateToLocal, convertDateToUTC } from "presentation/utils/timeUtil";
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { CommonField, DateRangePicker, FieldType } from "veronica-ui-component/dist/component/core";

interface ComponentProps {
    label?: string,
    dateRange: { startDate: any, endDate: any },
    onDatesChange: (startDate: any, endDate: any, dateFields: { startField: string, endField: string }) => void,
    onClose?: () => void,
    width?: string,
    errorMessage?: string,
    fieldNames?: { startField: string, endField: string },
    disabled?: boolean,
    optional?: boolean,
}

const DateRangePickerComponent: React.FC<ComponentProps> = (props) => {
    const componentRef = useRef<HTMLDivElement>(null);

    const [dateRangeInternal, setDateRangeInternal] = useState<{ startDate: any, endDate: any }>({
        startDate: (props.dateRange.startDate ? convertDateToLocal(moment(props.dateRange.startDate)) : null),
        endDate: (props.dateRange.endDate ? convertDateToLocal(moment(props.dateRange.endDate)) : null),
    });

    const onDatesChange = useCallback((startDate: any, endDate: any) => {
        if (startDate && endDate && moment(startDate).diff(moment(endDate)) > 0) {
            const tempDate = startDate;
            startDate = endDate;
            endDate = tempDate;
        }
        setDateRangeInternal({
            endDate: endDate ? moment(endDate) : null,
            startDate: startDate ? moment(startDate) : null
        });
        if (props.onDatesChange && props.fieldNames) {
            props.onDatesChange(startDate ? convertDateToUTC(moment(startDate))?.toDate() : null,
                endDate ? convertDateToUTC(moment(endDate))?.toDate() : null,
                { ...props.fieldNames })
        }
    }, [dateRangeInternal]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const isNotClickingDateRangePicker = componentRef.current && !componentRef.current.contains(event.target as Node)
            if (isNotClickingDateRangePicker) {
                // Clicked outside the component
                if (props.onClose) {
                    props.onClose()
                }
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        setDateRangeInternal({
            startDate: (props.dateRange.startDate ? convertDateToLocal(moment(props.dateRange.startDate)) : null),
            endDate: (props.dateRange.endDate ? convertDateToLocal(moment(props.dateRange.endDate)) : null),
        });
    }, [props.dateRange.startDate, props.dateRange.endDate]);

    return <div ref={componentRef}>
        {!props.disabled ?
            <DateRangePicker
                label={props.label ? props.label : ''}
                width={props.width ? props.width : '300px'}
                date={!props.dateRange.startDate && !props.dateRange.endDate ?
                    [] :
                    [(dateRangeInternal?.startDate ?? convertDateToLocal(moment(props.dateRange.startDate))).toDate(),
                    (dateRangeInternal?.endDate ?? convertDateToLocal(moment(props.dateRange.endDate)))?.toDate()]}
                optional={props.optional}
                errorMessage={props.errorMessage}
                onChange={(e) => {
                    if (e) {
                        const start = (e as unknown as Date[]).at(0)
                        const end = (e as unknown as Date[]).at(1)
                        onDatesChange(start, end)
                    }
                }} /> :
            <CommonField
                isReadOnly={true}
                readOnlyValue={
                    !dateRangeInternal?.startDate && !dateRangeInternal?.startDate && !props.dateRange.startDate && !props.dateRange.endDate ? "" :
                        (dateRangeInternal?.startDate || dateRangeInternal?.endDate ?
                            `${dateRangeInternal?.startDate ? convertDateToDateForComponent(dateRangeInternal?.startDate?.toDate()) : ""}${dateRangeInternal?.endDate ? ' - ' + convertDateToDateForComponent(dateRangeInternal?.endDate?.toDate()) : ""}` :
                            (props.dateRange.startDate || props.dateRange.endDate ?
                                `${props.dateRange.startDate ? convertDateToDateForComponent(props.dateRange.startDate) : ""}${props.dateRange.endDate ? ' - ' + convertDateToDateForComponent(props.dateRange.endDate) : ""}` :
                                ""
                            )
                        )
                }
                fieldLabel={props.label ? props.label : ''}
                fieldType={FieldType.TEXT}
                fieldKey={''}
                requiredFieldList={[]}
                onFieldChange={() => { }}
            />
        }
    </div>

}

export default memo(DateRangePickerComponent);